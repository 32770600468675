import { Pipe, PipeTransform } from '@angular/core';

import { BibliaInfoVerseModel } from '../../../../services/biblia-info/biblia-info.model';

@Pipe({
  name: 'searchVerseNo',
  standalone: true,
})
export class SearchVerseNoPipe implements PipeTransform {
  transform(verses: BibliaInfoVerseModel[]): number {
    return +verses[Math.floor(verses.length / 2)]?.verse;
  }
}
